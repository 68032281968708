export enum TenderStates {
  EVALUATING = 'EVALUATING',
  PUBLISHED = 'PUBLISHED',
  IN_PROGRESS = 'IN_PROGRESS',
  OFFERED = 'OFFERED',
  TERMINATED = 'TERMINATED',
  EXPIRED = 'EXPIRED',
  AWARDED = 'AWARDED',
  ALL_BIDS_REJECTED = 'ALL_BIDS_REJECTED',
}
